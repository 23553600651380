<template>
  <!-- <Header :header="header"></Header> -->
  <a-dims-search-bar
    placeholder="Buscar favoritos"
    hide-search>

    <router-link
      active-class="border-b-2 border-primary text-primary"
      to="/explore"
      class="inline-block py-4 font-bold">
      Explorar
    </router-link>
    <router-link
      active-class="border-b-2 border-primary text-primary"
      to="/favorites"
      class="inline-block py-4 ml-small font-bold">
      solicitados
    </router-link>

    <template #results>
      <div class="flex justify-center items-center flex-col" v-if="loading">
        <a-loader color="text-gray" class="h-10 w-10" />
        <h1 class="text-2xl text-white">Buscando</h1>
      </div>
    </template>
  </a-dims-search-bar>

  <div class="flex px-4 pt-20">
<!--    <div class="flex-grow">-->
<!--      <h3 class="text-left uppercase font-bold text-dgray">Restaurantes populares</h3>-->
<!--    </div>-->
<!--    <div class="flex-none">-->
<!--      <a-dropdown>-->
<!--        <p class="text-left text-gray-400 px-2">Ordenar por</p>-->
<!--        <a-dropdown-item @click="fetch({ sort_by: 'created_at,desc' })">-->
<!--          Mas recientes primero-->
<!--        </a-dropdown-item>-->
<!--        <a-dropdown-item @click="fetch({ sort_by: 'created_at,asc' })">-->
<!--          Mas recientes al final-->
<!--        </a-dropdown-item>-->
<!--      </a-dropdown>-->
<!--    </div>-->
  </div>

  <a-list
    class="px-4"
    :source="pagination.data"
    :loading="loading"
    :should-emit-bottom-reached="pagination.next_page_url !== null"
    @bottom-reached="fetch({
      page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page
    }, true)"
    #default="{ item, index }">

    <restaurant-cell
      @detach="detach(index)"
      @fetch="fetch({ page: 1, sort_by: 'created_at,desc', limit: 15 })"
      :restaurant="item"
      :foodie="user.foodie.id" />
  </a-list>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import RestaurantCell from './explore/restaurant-cell'

export default {
  name: 'Favorites',
  components: {
    RestaurantCell
  },
  data: () => ({
    pagination: [],
    loading: false,
    query: {
      limit: 15,
      sort_by: 'created_at,desc'
    },
    header: {
      type: 'explore'
    },
    gridType: 'favorite'
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user
    }),
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    })
  },
  methods: {
    fetch (segment = {}, appending = false) {
      this.loading = !this.loading
      const query = { ...this.query, ...segment }

      this.$repository.foodies
        .getInterested(this.user.foodie.id, query)
        .then(({ data }) => {
          const pagination = {
            ...data,
            data: data.data.map($0 => ({ ...$0, foodie_is_interested: true }))
          }

          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination

          this.query = query
        })
        .finally(() => (this.loading = !this.loading))
    },
    detach (index) {
      this.pagination = {
        ...this.pagination,
        data: this.pagination.data.filter((_, key) => key !== index)
      }
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.fetch()
      window.scrollTo({ top: 0 })
    }
  }
}
</script>
