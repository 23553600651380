<template>
  <!--  <Header :header="header" @multi-selection="multiSelection" @search="activeSearch"/>-->
  <a-dims-search-bar
      placeholder="Buscar Foodie"
      hide-search
      @search="fetch({ search: $event })"
      @multi-selection="multiSelection">
    <router-link
        active-class="font-bold border-b-2 border-primary text-primary"
        to="/explore"
        class="inline-block py-4">
      Explorar
    </router-link>
    <router-link
        active-class="font-bold border-b-2 border-primary text-primary"
        to="/interested"
        class="inline-block py-4 ml-small">
      Solicitudes
    </router-link>
    <router-link
        active-class="font-bold border-b-2 border-primary text-primary"
        to="/favorites"
        class="inline-block py-4 ml-small">
      favoritos
    </router-link>

    <template #results>
      <div class="flex justify-center items-center flex-col" v-if="loading">
        <a-loader color="text-gray" class="h-10 w-10" />
        <h1 class="text-2xl text-white">Buscando</h1>
      </div>
    </template>
    <!-- <template #results>
      <p class="text-xl text-white text-left">Resultados</p>
      <div class="bg-white p-2 rounded-lg">
        <a-list
          :source="pagination.data"
          :loading="loading"
          #default="{ item, index }">

          <restaurant-cell
            @attach="attach(index)"
            @detach="detach(index)"
            :restaurant="item"
            :foodie="user.foodie.id" />
        </a-list>
      </div>
    </template> -->
  </a-dims-search-bar>
  <div class="flex grid-header padding-header">
    <div class="flex-grow">
      <p class="text-left text-dgray font-bold uppercase">Foodies Favoritos</p>
    </div>
    <a-dropdown>
      <p class="text-left text-gray-400 px-2">Ordenar por</p>
      <a-dropdown-item @click="fetch({ sort_by: 'created_at,desc', explore: 1 })">
        Mas recientes primero
      </a-dropdown-item>
      <a-dropdown-item @click="fetch({ sort_by: 'created_at,asc', explore: 1 })">
        Mas recientes al final
      </a-dropdown-item>
    </a-dropdown>
  </div>

  <div class="text-left mx-normal my-small flex border border-gray rounded-full items-center overflow-hidden">
    <input
      ref="search"
      autofocus
      v-debounce:[400]="search => fetch( {search: search})"
      placeholder="Buscar Foodies"
      class="flex-auto bg-opacity-75 bg-white text-black focus:outline-none p-2">
    <i class="icon-search inline flex-none p-2 text-xl" />
  </div>

  <a-list
      class="flex flex-col gcp-restaurant-explore"
      :class="button ? 'public-collab' : ''"
      :source="pagination.data"
      :loading="loading"
      :should-emit-bottom-reached="pagination.next_page_url !== null"
      @bottom-reached="fetch({ page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page }, true)"
      #default="{ item }">
    <GridElement :value="item"
                 class="grid-element-padding"
                 :grid-type="'favorite'"
                 :multi="multi"
                 @selected-foodie="addFoodie"
                 @unselected-foodie="unselectFoodie">
    </GridElement>
  </a-list>
  <!--  <div class="gcp-restaurant-explore padding-header" :class="button ? 'public-collab' : ''">-->
  <!--    <Grid :multi="multi" :view-type="'explore'" @show-button="viewButton"></Grid>-->
  <!--  </div>-->
  <div v-show="button && multi" class="block cbc bg-white">
    <button @click="createMultiCollab"
            class="button bg-primary font-normal text-white uppercase h-base">
      {{'invitar a ' + totalFoodies + ' Foodies' }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import GridElement from '@/components/authenticated/GridElement'
// import Header from '@/components/authenticated/Header'

export default {
  name: 'Favorites',
  props: {
    values: Object
  },
  components: {
    GridElement
    // Header
  },
  data () {
    return {
      // values: [],
      multi: false,
      search: false,
      button: false,
      totalFoodies: 0,
      foodies: [],
      modal: null,
      pagination: { data: [] },
      loading: false,
      query: {
        limit: 15,
        sort_by: 'created_at,desc',
        explore: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
      // pagination: state => state.foodies.pagination,
      // loading: state => state.foodies.loading.index
    })
  },
  methods: {
    multiSelection (multi, search) {
      this.multi = multi
      this.search = search
      // console.log(this.multi)
    },
    addFoodie (id) {
      // this.foodie.push(foodie.valueOf())
      this.foodies.push(id)
      this.selected = true
      this.viewButton()
    },
    unselectFoodie (id) {
      // this.foodie.push(foodie.valueOf())
      this.foodies.splice(this.foodies.indexOf(id), 1)
      this.selected = false
      this.viewButton()
    },
    viewButton () {
      this.button = this.foodies.length > 0
      this.totalFoodies = this.foodies.length
    },
    createMultiCollab () {
      if (this.foodies.length > 1) {
        this.$router.push({ name: 'collaboration.create.multi', query: { ids: this.foodies.join(',') } })
      } else {
        this.$router.push({ path: `/collaboration/create/${this.foodies}` })
      }
    },
    update (collaboration, index) {
      this.pagination = {
        ...this.pagination,
        data: this.pagination.data
          .map(($0, $1) => $1 === index ? { ...$0, ...collaboration } : $0)
      }
    },
    fetch (segment = {}, appending = false) {
      this.loading = !this.loading
      const query = { ...this.query, ...segment }
      this.$repository.restaurants
        .getFavorites(this.user.restaurant.id, query)
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination
          this.query = query
        })
        .finally(() => (this.loading = !this.loading))
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.fetch()
    }
  }
}
</script>

<style scoped>
.button{
  margin: 0 !important;
}
</style>
