<template>
  <div>
    <component :is="`favorites-${user.isFoodie ? 'foodies' : 'restaurants'}`"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import favoritesFoodies from '@/views/authenticated/foodies/Favorites.vue'
import favoritesRestaurants from '@/views/authenticated/restaurants/Favorites.vue'

export default {
  name: 'Favorites',
  components: {
    favoritesFoodies,
    favoritesRestaurants
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  }
}
</script>
